/* @font-face { */
  /* font-family: 'Roboto Slab'; */
  /* src: local('Roboto Slab'), url('/assets/font/robotoslab-variablefont_wght-webfont.woff2') format('woff2'), url('/assets/font/robotoslab-variablefont_wght-webfont.woff') format('woff'); */
  /* font-weight: normal; */
  /* font-style: normal; */
/* } */

:root {
  --color-canvas: white;
  --background-color: #f6f7f4;
  --brand-color: oklch(0.6 0.13 168.23 / 1);
  --content-width: 600px;
  --sidebar-width: 27vw;
  --max-figure-size: 25vw;
  --gap-width: 15px;
  scroll-behavior: smooth;
}

$base-font-size: 17px;
$text-color: #211A2A !default;
$base-font-family: 'Iowan Old Style', 'Palatino Linotype', 'URW Palladio L', P052, 'Noto Serif', serif;

@import "whiteglass";

body {
  background-color: var(--background-color);
  display: grid;
  grid-template-columns: [main-start] 1fr [sidebar-start] var(--sidebar-width) [sidebar-end];
  grid-gap: 15px;
}

article, .home {
  grid-column: main-start;
  container-type: size;
  padding-top: var(--gap-width);
}

.post-header, .post-content, .post-continue, .post-archives, .pagination {
  display: grid;
  grid-template-columns: [gutter-start] 1fr [content-start] var(--content-width) [content-end]; 

  &> * {
    grid-column: content-start;
  }
}

.post-content {
  text-align: justify;
  text-wrap: pretty;
  hyphens: auto;
  hyphenate-limit-chars: 9;
}


.site-header {
  grid-column: sidebar-start;
  container-type: size;
  position: fixed;
  bottom: 0;
  right: 0;
  width: var(--sidebar-width);
  background: var(--brand-color);
  color: var(--background-color);
  height: 100vh;
  
  .wrapper {
    position: fixed;
    bottom: 50px;
    right: 10cqi;
    font-size: 7cqw;
  }
  
  .site-title {
    font-size: 20cqw;

    &:hover {
      text-decoration-thickness: 2px;
      text-decoration-skip-ink: all;
    }
  }
    
  .tagline {
    margin-top: 13cqi;
    font-style: italic;
    font-size: 5cqw;
  }

  nav a {
    margin: 0;
  }
  
  nav a, .site-title {
    display: block;
    color: var(--background-color) !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sidecar {
  figure {
    margin-right: 30px;

    width: min(calc(100cqw - 70%), var(--max-figure-size));
    min-width: 25cqw;
    margin-left: max(calc(var(--content-width) - 100cqw + 30px), -30px - var(--max-figure-size));
    float: left;

    img {
      border-radius: 10px;
    }
  }
}

.subgrid {
  grid-column: gutter-start / content-end;
  display: grid;
  grid-template-columns: auto min(40%, var(--content-width)); 
  /* grid-template-columns: subgrid; */
  grid-gap: calc(var(--gap-width) * 2);
}

.sticky-diagram img {
  position: sticky;
  top: var(--gap-width);
  margin-left: var(--gap-width);
}

hr {
  width: 300px;
  margin: 0 auto 15px auto;
}

.post-continue a {
  width: fit-content;
}

.pagination,
.page-heading {
  max-width: $content-width;
  margin: auto;
}

.post-list li {
  list-style-type: none;
  border-top: none !important;
}

.wrapper::after {
  all: unset;
}

/* .wrapper > * { */
/* grid-column: 2; */
/* } */

.site-title {
  flex-grow: 1;
  float: none;
}

.site-nav {
  float: none;
  margin-top: 12px;
}

h1.post-title {
  line-height: 1.2;

  /* a { */
    /* color: $text-color; */
  /* } */
}

.post-subtitle {
  font-style: italic;
  margin-left: 25px;
  // max-width: calc($content-width * 0.7);
}

#small-toc {
  font-size: 12px;
  position: fixed;
  top: 20px;
  max-width: 120px;

  transition-property: opacity;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  &.hidden {
    opacity: 0;
  }

  .active {
    font-weight: bold;
  }

  li {
    margin-bottom: 0;
    list-style-type: "⯈ ";

    &::marker {
      color: adjust-color($grey-color, $lightness: 25%);
    }

    ul {
      margin-left: 15px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
.highlight {
  margin-bottom: 7.5px;
}

.videoPlayer-right {
  float: right;
  width: 500px;
  margin: 2em;
  margin-right: -150px;
}

.videoPlayer-left {
  float: left;
  width: 500px;
  margin: 2em;
  margin-left: -150px;
}

@media screen and (max-width: 980px) {
  #small-toc {
    display: none;
  }
}

@import "phone";

small.credit {
  display: block;
  text-align: left;
  font-style: italic;
  font-size: 11px;
  margin-top: 3px;
}

.center {
  margin: 0 auto;
  text-align: center;
  max-width: 75%;
}

.hero {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 25px;
  max-width: 100%;
  width: 100%;
  margin-left: 0;
  /* width: 140%; */
  /* max-width: 140%; */
  /* margin-left: -20%; */
}

@media screen and (max-width: 980px) {
  .hero {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
}


.medium {
  width: 768px;
  max-width: 100%;
  height: auto;
}

.small-medium {
  max-width: 50%;
  height: auto;
}

.margin-bottom {
  margin-bottom: 0.5em;
}

.nomargin {
  margin: auto 0;
}

ol,
ul {
  /* margin-left: 0 !important; */
  /* margin-bottom: 15px !important; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
}

li {
  list-style-type: disc;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 290px);
  grid-gap: 15px;
  justify-content: center;
  align-items: center;

  img {
    height: auto;
  }

}

@include media-query($on-palm) {
  .gallery {
    grid-template-columns: 100%;

    img {
      width: 100%;
    }
  }
}

.tracklist {
  columns: 2;
  column-gap: 80px;
  width: 100%;
  margin: 0 auto;

  li {
    break-inside: avoid;
    font-style: italic;
    // margin-bottom: 5px;


    .artist {
      font-weight: bold;
      font-style: normal;
    }
  }
}

@include media-query($on-palm) {
  .tracklist {
    columns: 1;
    width: 85%;
    margin: auto;
  }
}

.card {
  text-align: center;
  width: 254px;

  img {
    margin-bottom: 10px;
    border-radius: 10px;
    width: 254px;
    height: 102px;
    object-fit: cover;
  }
}

.cv-data {
  width: auto;
  line-height: normal;

  td {
    padding: 0.4em;
    margin-bottom: 0.7em;
    vertical-align: baseline;
  }

  td:first-child {
    text-align: right;
    border-bottom: 1px dotted var(--brand-color);
  }

  td:last-child {
    border-bottom: 1px dotted var(--brand-color);
    border-left: 1px dotted var(--brand-color);
  }

  tr:last-child {
    td {
      border-bottom: none
    }
  }

  &.headers-on-left {
    td:first-child {
      font-weight: bold;
    }
  }
}


.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 1em;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

code {
  // padding: 4px 5px 1px 5px;

  padding-top: 3px;
  // vertical-align: middle;
}

/* .highlight { */
  /* background-color: transparent; */
/* } */

figure.highlight pre {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #eee;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;

  code {
    counter-reset: code;

    div {
      background-color: #eee;
      counter-increment: code;

      &:before {
        content: counter(code);

        width: 16px;
        text-align: right;

        display: inline-block;
        padding-left: 15px;
        padding-right: 5px;
        margin-right: 10px;
        background-color: #aaa;
        color: white;
      }
    }
  }
}

.table_with_borders {
  table {
    border-collapse: collapse;
    width: auto;
  }

  th {
    padding: 5px;
  }

  td {
    border: 1px solid black;
    padding: 3px;
  }

  td:first-child {
    border: none;
  }
}

.post-archives {
  li {
    list-style-type: none;
  }
}
