@media screen and (max-width: 850px) {
  body {
    display: block;
  }
  
  .post-content, .post-header, .sidecar {
    padding: 15px;
    display: block;
    text-align: left;
  }

  .sidecar {
    padding: 0;
    
    figure {
      width: 50%;
      margin-left: 0;
      margin-right: var(--gap-width)
    }
  }
  .site-header {
    width: 100%;
    container-type: normal;
    height: auto;
    position: static;
  
    .wrapper {
      position: static;
    }
    
    .titles {
      display: flex;
      margin-bottom: 10px;
    }

    .site-title {
      margin-left: 15px;
      font-size: 50px;
    }

    .tagline {
      text-align: right;
      font-size: 12px;
      margin-right: 15px;
      max-width: 98px;
      margin-top: 10px;
      margin-bottom: 0;
    }

    nav {
      margin: 0;
      line-height: inherit;
      font-size: 13px;

      a.page-link {
        padding-bottom: 3px;
        display: inline-block;
        padding-top: 0;
      }
    }
  }


  h1.post-title {
    font-size: 24px;
  }

  .page-content {
    padding: 15px 0;
    font-size: 14px;
  }

  .post-list>li {
    margin-bottom: 20px;

    &:not(:first-child) {
      padding-top: 20px;
    }
  }

  .videoPlayer-right {
    float: none;
    width: 100%;
    margin: 0;
  }
}
