// Define defaults for each variable.
$base-font-family: Bitter, "Apple SD Gothic Neo", "Noto Sans", "Source Han Sans", "Noto Sans CJK JP", "Source Han Sans JP", "Noto Sans CJK KR", "Source Han Sans KR", NanumBarunGothic, AppleGothic, "Malgun Gothic", Dotum, sans-serif !default;
$monospace-font-family: Monaco, Menlo, Consolas, "Courier New", DotumChe, monospace !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:        #387080 !default;

$grey-color:       #6f6776 !default;
$grey-color-light: adjust-color($grey-color, $lightness: 45%) !default;
$grey-color-dark:  adjust-color($grey-color, $lightness: -20%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          800px !default;
$on-laptop:        800px !default;



// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit * 0.5;
//     padding-left: $spacing-unit * 0.5;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials.
@import
  "whiteglass/base",
  "whiteglass/layout"
;
