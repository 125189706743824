@include media-query($on-palm) {
  body {
    flex-direction: column;
  }

  .site-header {
    width: 100%;
    /* height: 19cqw; */
    align-content: start;
    container-type: normal;
  
    .wrapper {
      all: unset;

      display: flex;
      flex-direction: column;
    }

    .titles {
      display: flex;
    }

    .site-title {
      /* margin-top: 10px; */
      /* vertical-align: middle; */
      /* display: inline; */
      margin-left: 15px;
      font-size: 8cqw;
    }

    .tagline {
      /* vertical-align: middle; */
      /* display: inline-block; */
      text-align: right;
      /* margin-top: 12px; */
      font-size: 12px;
      margin-right: 15px;
      max-width: 98px;
      margin-top: 10px;
      /* margin-left: 10px; */
      margin-bottom: 0;
      /* margin-top: 0; */
      /* position: relative; */
      /* right: 10px; */
      /* top: 10px; */
    }

    nav {
      margin: 0;
      line-height: inherit;
      font-size: 13px;

      a.page-link {
        padding-bottom: 3px;
        display: inline-block;
        padding-top: 0;
      }
    }
  }


  h1.post-title {
    font-size: 24px;
  }

  .page-content {
    padding: 15px 0;
    font-size: 14px;
  }

  // .post-content > * {
  //   max-width: 86vw;
  // }

  .post-list>li {
    margin-bottom: 20px;

    &:not(:first-child) {
      padding-top: 20px;
    }
  }

  .videoPlayer-right {
    float: none;
    width: 100%;
    margin: 0;
  }
}
