/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  word-wrap: break-word;
}

table {
  width: 100%;
  margin: 0 0 20px 0;
  text-align: left;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit * 0.5;
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure>img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul,
ol {
  margin-left: $spacing-unit;
}

li {

  >ul,
  >ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $base-font-weight;

  code {
    font-size: inherit;
  }
}

h1,
h2,
h3 {
  &:lang(ko) {
    word-break: keep-all;
  }
}



/**
 * Links
 */
a {
  color: var(--brand-color);
  /* text-decoration: none; */

  &:visited {
    color: color-mix(in oklch, var(--brand-color), black 10%);
  }

  &:hover {
    color: color-mix(in oklch, var(--brand-color), black 25%);

    /* color: var(--brand-color) */
    /* text-decoration: underline; */
  }
}



/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit * 0.5;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }

  pre,
  code {
    color: var(--brand-color);
  }
}



/**
 * Rules
 */
hr {
  height: 4px;
  margin: $spacing-unit * 0.5 0;
  border: 0;
  background-color: $grey-color-light;
}



/**
 * Code formatting
 */
pre,
code {
  font-family: $monospace-font-family;
  font-size: 13px;
  border: 1px solid $grey-color-light;
  border-radius: 0;
  /* background-color: #f8f8f8; */
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;
  word-wrap: normal;

  >code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
/* .wrapper { */
  /* max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2)); */
  /* max-width: calc(#{$content-width} - (#{$spacing-unit} * 2)); */
  /* margin-right: auto; */
  /* margin-left: auto; */
  /* padding-right: $spacing-unit; */
  /* padding-left: $spacing-unit; */
  /* @extend %clearfix; */

  /* @include media-query($on-laptop) { */
    /* max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit})); */
    /* max-width: calc(#{$content-width} - (#{$spacing-unit})); */
    /* padding-right: $spacing-unit * 0.5; */
    /* padding-left: $spacing-unit * 0.5; */
  /* } */
/* } */



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}
